import { PUBLIC_BASE_URL, PUBLIC_API_KEY } from '@config'
import { queryString } from '@helpers/utils.js'
import { createClient } from '@services'

const { get } = createClient()

/* API request to get current agreement */
export async function getCurrentAgreementRequest(agreementTemplateId) {
  const query = { api_key: PUBLIC_API_KEY }
  const { data } = await get(
    `${PUBLIC_BASE_URL}/agreements/types/${agreementTemplateId}/current${queryString(
      query
    )}`
  )

  return data
}

/* API request to get agreement version */
export async function getAgreementVersionRequest(agreementVersionId) {
  const query = { api_key: PUBLIC_API_KEY }
  const { data } = await get(
    `${PUBLIC_BASE_URL}/agreements/versions/${agreementVersionId}${queryString(
      query
    )}`
  )
  return data
}
