import { NEW_RESOURCE_ID, GAME_ID } from '@config'
import { PROFILE_ROUTE } from '@config/routeNames.js'
import { gameStore, modStore } from '@stores'
import zipObject from 'lodash/zipObject'
import { useRoute } from '@composables'
import { ref, watch } from 'vue'

const acceptedRouteParams = ['game', 'mod']
const { getPublicGame } = gameStore()
const { getModById } = modStore()

const waitingforDynamicResources = ref([])
// ensures only watch is running at a time
let watching = false
const triggerWatch = ref(0)

let stop = () => {}

export default function () {
  const { routeName, routeParams } = useRoute()

  // called once
  function init() {
    watchDynamic()
  }

  function registerResourceWatch(callback, skipVariableNumCheck = false) {
    waitingforDynamicResources.value.push({ callback, skipVariableNumCheck })
    if (!watching) {
      triggerWatch.value++
      watching = true
    }
  }

  function watchDynamic() {
    watch([routeParams, triggerWatch], (newVals) => {
      const _routeParams = { ...newVals[0], game: GAME_ID }
      if (_routeParams || (_isProfileRoute() && !watching)) {
        let _watch = _getWatches(_routeParams, routeName.value)
        watching = true
        stop && stop()
        stop = watch(
          _watch,
          (newVals) => {
            let values = newVals
            if (waitingforDynamicResources.value.length === 0) {
              watching = false
              stop && stop()
              return
            }
            const keys = []
            Object.entries(_routeParams).forEach(([key, value]) => {
              if (
                acceptedRouteParams.includes(key) &&
                value !== NEW_RESOURCE_ID
              )
                keys.push(key)
            })

            const result = zipObject(keys, values)

            if (result && Object.keys(result).length !== keys.length) return

            const canRunTasks = waitingforDynamicResources.value.filter(
              (t) => !!t.skipVariableNumCheck
            )

            if (canRunTasks.length) {
              canRunTasks.forEach((t) => t.callback(result))

              waitingforDynamicResources.value =
                waitingforDynamicResources.value.filter(
                  (t) => !t.skipVariableNumCheck
                )
            }

            if (!values || values.some((e) => !e)) return

            waitingforDynamicResources.value.forEach((t) => t.callback(result))
            waitingforDynamicResources.value = []
            watching = false
            stop && stop()
          },
          { immediate: true }
        )
      }
    })
  }

  function _isProfileRoute() {
    return routeName.value?.includes(PROFILE_ROUTE)
  }

  function _getWatches(_routeParams) {
    const _watch = []
    Object.entries(_routeParams).forEach(([key, nameId]) => {
      if (nameId === NEW_RESOURCE_ID) return

      if (key === 'game') {
        _watch.push(getPublicGame(nameId))
      } else if (key === 'mod') {
        _watch.push(getModById(GAME_ID, nameId))
      }
    })

    return _watch
  }

  function clearResourceWatchList() {
    waitingforDynamicResources.value = []
  }

  return {
    clearResourceWatchList,
    registerResourceWatch,
    init,
  }
}
