<template>
  <div
    class="tw-w-full tw-flex tw-justify-center tw-text-light-1 tw-px-4 xs:tw-px-6 tw-mt-20"
  >
    <div class="tw-container-max-width tw-w-full">
      <div
        class="tw-w-full tw-flex tw-justify-between tw-border-b-2 tw-border-dark"
      >
        <div class="tw-flex tw-space-x-2 tw-text-dark">
          <base-link :to="{ name: MOD_BROWSE_ROUTE }">
            <base-button dark radius="top">{{ $t('browse') }}</base-button>
          </base-link>
          <base-link
            class="tw-relative"
            :to="{ name: PROFILE_LIBRARY_ROUTE }"
            @click="goToCollection"
          >
            <base-button dark radius="top"
              >{{ $t('collection')
              }}<span v-if="isLoggedIn" class="tw-inline-block tw-w-4"
                >&nbsp;</span
              ></base-button
            >
            <div
              v-if="isLoggedIn"
              class="tw-absolute tw-top-2 tw-right-1 tw-bg-primary-2 tw-rounded-full tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center tw-pointer-events-none"
            >
              {{ collectionCount }}
            </div>
          </base-link>
        </div>
        <div v-if="isLoggedIn" class="tw-flex tw-items-center">
          <base-link
            :to="{ name: MOD_BROWSE_ROUTE, query: { submitted_by: user.id } }"
            class="tw-mr-4"
            @click="filterByUser"
          >
            <div
              class="tw-flex tw-items-center tw-text-light-1 hover:tw-text-tertiary focus:tw-text-tertiary tw-util-text-shadow"
            >
              <span class="tw-text-sm tw-font-bold tw-mr-2">
                {{ user.username }}
              </span>
              <base-avatar :user="user" hide-popover />
            </div>
          </base-link>
          <base-button dark radius="top" @click="logout">{{
            $t('logout')
          }}</base-button>
        </div>
        <base-button v-else dark radius="top" @click="triggerModal">{{
          $t('login')
        }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { MOD_BROWSE_ROUTE, PROFILE_LIBRARY_ROUTE } from '@config/routeNames'
import { useAsync, useNavMenus, useRoute, useSearch } from '@composables'
import { authStore, localModStore, subscriptionStore } from '@stores'
import { setGlobalModal } from '@composables/useModal.js'
import { MODAL_LOGIN } from '@config/globalModals.js'
import { KEYS_MODS } from '@config/storeKeys'
import { logoutRequest } from '@services'
import { computed } from 'vue'

export default {
  setup() {
    const { user, isLoggedIn, clearUser } = authStore()
    const { subscriptions } = subscriptionStore()
    const { localMods } = localModStore()
    const { showUserDropdown, showMenu } = useNavMenus()
    const { getGameId } = useRoute()
    const browseId = `${KEYS_MODS}${getGameId().value}`
    const { setSubmittedBy, searchUserName } = useSearch(browseId)
    const { run } = useAsync(logoutRequest)

    const collectionCount = computed(
      () => (subscriptions.value?.length || 0) + (localMods.value?.length || 0)
    )

    function triggerModal() {
      setGlobalModal({ modal: MODAL_LOGIN })
    }

    function goToCollection(e) {
      if (!isLoggedIn.value) {
        e.preventDefault()
        triggerModal()
      }
    }

    function logout() {
      run()
      setTimeout(() => {
        clearUser()
        setTimeout(() => window.location.reload(), 200)
      }, 10)
    }

    function filterByUser() {
      setSubmittedBy(user.value.id)
      searchUserName.value = user.value.username
    }

    return {
      PROFILE_LIBRARY_ROUTE,
      MOD_BROWSE_ROUTE,
      showUserDropdown,
      collectionCount,
      goToCollection,
      subscriptions,
      filterByUser,
      triggerModal,
      isLoggedIn,
      showMenu,
      logout,
      user,
    }
  },
}
</script>
