<template>
  <div
    class="tw-fixed tw-flex tw-flex-col-reverse tw-items-start tw-right-4 tw-top-20 tw-z-41"
  >
    <div
      v-for="(item, index) in activeToasts"
      :key="index"
      class="tw-w-80 tw-rounded tw-util-shadow-xl tw-px-5 tw-mb-4 tw-flex tw-py-4 tw-overflow-hidden tw-relative tw-bg-theme-1 tw-text-theme tw-border-grey tw-border-opacity-40"
    >
      <base-button
        v-if="item.hasClose"
        subtle
        input-btn
        icon="times"
        icon-size="lg"
        sr-only="dismiss alert"
        class="tw-absolute tw-top-0 tw-right-0"
        @click.prevent="close(item.name)"
      >
      </base-button>

      <div
        class="tw-absolute tw-top-0 tw-left-0 tw-w-2 tw-h-full tw-bg-primary-2"
      />

      <div class="tw-flex tw-space-x-3">
        <font-awesome-icon
          :icon="typeIcon(item)"
          class="tw-text-primary-2"
          size="2x"
        />
        <div class="tw-space-y-1">
          <span v-if="item.title" class="tw-flex tw-font-bold">
            {{ item.title }}
          </span>
          <span v-if="item.text" class="tw-flex tw-text-xs">
            {{ item.text }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from '@composables'
export default {
  setup() {
    const { activeToasts, close } = useToast()

    function typeTextStyle(name) {
      if (name.isSuccess) {
        return 'tw-text-success'
      } else if (name.isWarning) {
        return 'tw-text-warning'
      } else if (name.isError) {
        return 'tw-text-danger'
      } else {
        return 'tw-text-info'
      }
    }

    function typeIcon(name) {
      if (name.isSuccess) {
        return 'check-circle'
      } else if (name.isWarning) {
        return 'exclamation-circle'
      } else if (name.isError) {
        return 'times-circle'
      } else {
        return 'info-circle'
      }
    }

    function typeBgStyle(name) {
      if (name.isSuccess) {
        return 'tw-bg-success'
      } else if (name.isWarning) {
        return 'tw-bg-warning'
      } else if (name.isError) {
        return 'tw-bg-danger'
      } else {
        return 'tw-bg-info'
      }
    }

    return {
      typeTextStyle,
      typeBgStyle,
      activeToasts,
      typeIcon,
      close,
    }
  },
}
</script>
