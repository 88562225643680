module.exports = {
  BREAKPOINTS: {
    xxs: '376px',
    xs: '512px',
    sm: '640px',
    md: '768px',
    lg: '1048px',
    xl: '1388px',
    '2xl': '1536px',
    '3xl': '1750px',
    '4xl': '2600px',
    '5xl': '3200px',
    '6xl': '4500px',
  },
}
