import { PUBLIC_BASE_URL, PUBLIC_API_KEY } from '@config'
import { queryString } from '@helpers/utils.js'
import { createClient } from '@services'

const { get } = createClient()

/* API request to get team members for mod*/
export async function getModTeamMembersRequest(gameId, modId) {
  const query = { api_key: PUBLIC_API_KEY }
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/${gameId}/mods/${modId}/team${queryString(query)}`
  )
  return data
}
