<template>
  <loading-select v-if="inputLoading" :label="label"></loading-select>

  <div v-else ref="target" class="tw-text-theme tw-relative" :class="wrapWidth">
    <div v-if="tip || label" class="tw-flex tw-label-gap">
      <base-input-label
        v-if="label"
        :id-for="inputId"
        :required="required"
        :label="label"
        :tip="tip"
        :tip-hover="tipHover"
        :label-between="labelBetween"
      />
    </div>
    <div
      class="tw-flex tw-group tw-overflow-hidden tw-relative tw-bg-input-hover tw-duration-200 tw-ease-in-out"
      :class="[
        inputWidth,
        inputRounded,
        borderStyles,
        {
          'focus-within:tw-border-primary dark:focus-within:tw-border-primary':
            !disabled,
          'tw-input--height-large': large,
        },
        show && !IS_IOS_TOUCH_DEVICE
          ? 'tw-border-t-2 tw-border-l-2 tw-border-r-2'
          : 'tw-border-2',
        small
          ? 'tw-input--height-small tw-text-sm'
          : 'tw-input--height tw-input--text-size',
      ]"
      style="transition-property: background-color"
    >
      <select
        :id="inputId"
        ref="select"
        :placeholder="placeholder"
        :disabled="disabled"
        :aria-describedby="`${inputId}Help`"
        class="tw-max-w-full tw-bg-transparent"
        :class="[
          inputBaseStyles,
          { 'tw-util-truncate-one-line': selectedValues?.length > 0 },
        ]"
        :tabindex="disabled ? '-1' : '0'"
        @mousedown.prevent="openDropdown"
        @keydown="handleKeyPress"
        @change="valueChange"
      >
        <option
          v-if="showPlaceholder || selectedValues?.length > 0"
          value=""
          disabled
          selected
        >
          {{ selectedValues?.length > 0 ? selectionString : placeholder }}
        </option>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :selected="isSelected(option.value) && !selectedValues"
          :class="{ 'tw-hidden': !IS_IOS_TOUCH_DEVICE }"
        >
          {{ option.text }}
        </option>
      </select>
      <div
        class="tw-text-theme group-hover:tw-text-primary-2-hover"
        :class="[caretStyles]"
      >
        <font-awesome-icon icon="angle-down" />
      </div>
    </div>
    <div
      v-if="show && !IS_IOS_TOUCH_DEVICE"
      ref="dropdown"
      class="tw-absolute tw-inset-x-0 tw-cursor-default tw-shadow-sm tw-global--border-radius-b tw-overflow-auto tw-max-h-60 tw-bg-[#564C43] tw-z-10 tw-border-2 tw-border-primary tw-border-t-0"
    >
      <template v-if="!selectedValues">
        <div
          v-for="(option, index) in options"
          :key="option.value"
          class="tw-p-2 tw-input--pl hover:tw-text-primary-text hover:tw-bg-primary-hover"
          :class="isFocussed(index)"
          @click="selectItem(option.value, !selectedValues)"
        >
          {{ option.text ? option.text : '&nbsp;' }}
        </div>
      </template>
      <template v-else>
        <div v-for="option in options" :key="option.value">
          <base-checkbox
            dark-bg
            no-radius
            custom-label-styles="tw-p-2 tw-input--pl tw-text-light-2 hover:tw-text-primary-text hover:tw-bg-primary-hover"
            :label="option.text"
            :background="false"
            :model-value="
              selectedValues.includes(option.value) ||
              (selectedValues.length < 1 && option.value === defaultValue)
            "
            @input="selectItem(option.value, !selectedValues)"
          />
        </div>
      </template>
    </div>

    <div
      v-if="hasErrors || hint"
      class="tw-flex tw-flex-col tw-hint-gap tw-space-y-1"
    >
      <base-input-errors :errors="errors" align="left" />

      <base-input-hint v-if="hint" :id="`${inputId}Help`">
        {{ hint }}
      </base-input-hint>
    </div>
  </div>
</template>

<script>
import LoadingSelect from '@components/Loading/LoadingSelect.vue'
import { ref, toRefs, computed, onMounted, nextTick } from 'vue'
import { genHtmlId, isObjEmpty } from '@helpers/utils.js'
import { IS_IOS_TOUCH_DEVICE } from '@config'
import { onClickOutside } from '@vueuse/core'
import { useToggle } from '@composables'

export default {
  components: {
    LoadingSelect,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    modelValue: {
      type: [Number, String],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    tipHover: {
      type: Boolean,
      default: false,
    },
    labelBetween: {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    straightLeft: {
      type: Boolean,
      default: false,
    },
    selectedValues: {
      type: Array,
      default: undefined,
    },
    defaultValue: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'change'],
  setup(props, { emit }) {
    const {
      selectedValues,
      straightLeft,
      placeholder,
      modelValue,
      fullWidth,
      disabled,
      options,
      errors,
      id,
    } = toRefs(props)
    const target = ref()
    const select = ref()
    const dropdown = ref()
    const focussedIndex = ref(-1)
    const { show, open, close } = useToggle()
    const inputId = id.value ? id.value : genHtmlId()

    const caretStyles =
      'tw-pointer-events-none tw-absolute tw-w-8 tw-inset-y-0 tw-right-1 tw-flex tw-items-center tw-justify-center tw-button-transition'

    const showPlaceholder = computed(
      () => modelValue.value === '' && placeholder.value !== ''
    )

    const selectionString = computed(() =>
      options.value
        .filter((o) => selectedValues.value.includes(o.value))
        .map((o) => o.text)
        .join(', ')
    )

    const hasErrors = computed(() => !isObjEmpty(errors.value))

    const inputBaseStyles = computed(() => {
      let classList =
        ' tw-flex tw-flex-1 tw-input--pl tw-border-none tw-outline-none'
      return disabled.value
        ? `${classList} tw-cursor-default tw-pr-10`
        : `${classList} tw-cursor-pointer tw-button-transition tw-leading-normal tw-appearance-none tw-pr-12`
    })

    const borderStyles = computed(() => {
      if (show.value) {
        return 'tw-border-primary'
      }
      return hasErrors.value
        ? 'tw-border-danger tw-border-2'
        : 'tw-input--border-color'
    })

    const inputWidth = computed(() => (fullWidth.value ? 'tw-w-full' : ''))

    const inputRounded = computed(() =>
      show.value && !IS_IOS_TOUCH_DEVICE
        ? [
            'tw-global--border-radius-tr',
            { 'tw-global--border-radius-tl': !straightLeft.value },
          ]
        : straightLeft.value
        ? 'tw-global--border-radius-r'
        : 'tw-global--border-radius'
    )

    const wrapWidth = computed(() =>
      fullWidth.value ? 'tw-block tw-w-full' : 'tw-inline-block'
    )

    function isSelected(option) {
      if (option || option === 0) {
        return option.toString() === modelValue.value?.toString()
      }
      return false
    }

    onMounted(_resetFocus)

    function _resetFocus() {
      focussedIndex.value = options.value.findIndex(
        (option) => option.value.toString() === modelValue.value?.toString()
      )
    }

    function _closeAndResetFocus() {
      close()
      _resetFocus()
    }

    function _scrollTo() {
      if (focussedIndex.value >= 0) {
        dropdown.value?.children[focussedIndex.value].scrollIntoView({
          block: 'nearest',
        })
      }
    }

    function isFocussed(index) {
      return index === focussedIndex.value
        ? 'tw-bg-primary tw-text-primary-text'
        : 'tw-text-light-2'
    }

    function openDropdown() {
      if (!show.value) {
        open()
        _resetFocus()

        // Scroll to focussed index after dropdown has opened
        nextTick(_scrollTo)
        select.value.focus()
      } else {
        _closeAndResetFocus()
      }
    }

    function selectItem(value, closeDropdown = true) {
      emit('input', value)
      emit('change', value)
      if (closeDropdown) close()

      focussedIndex.value = options.value.findIndex(
        (option) => option.value.toString() === value.toString()
      )
      select.value.focus()
    }

    function valueChange(e) {
      const value = e.target.value
      selectItem(value, false)
      _scrollTo()
    }

    function changeIndex(dir) {
      focussedIndex.value =
        (focussedIndex.value + dir + options.value.length) %
        options.value.length

      if (!show.value && options.value.length)
        selectItem(options.value[focussedIndex.value].value)

      _scrollTo()
    }

    function handleKeyPress(e) {
      switch (e.keyCode) {
        case 9: //tab
          _closeAndResetFocus()
          break

        case 27: //escape
          if (show.value) {
            e.preventDefault()
            e.stopPropagation()
            _closeAndResetFocus()
          }
          break

        case 13: //return
        case 32: //space
          e.preventDefault()
          if (show.value) {
            if (focussedIndex.value >= 0) {
              selectItem(options.value[focussedIndex.value].value)
            }
          } else {
            openDropdown()
          }
          break

        case 38: //up
          e.preventDefault()
          changeIndex(-1)
          break

        case 40: //down
          e.preventDefault()
          changeIndex(1)
          break
      }
    }

    onClickOutside(target, _closeAndResetFocus)

    return {
      IS_IOS_TOUCH_DEVICE,
      showPlaceholder,
      inputBaseStyles,
      handleKeyPress,
      selectionString,
      openDropdown,
      inputRounded,
      borderStyles,
      caretStyles,
      valueChange,
      inputWidth,
      isFocussed,
      isSelected,
      selectItem,
      hasErrors,
      wrapWidth,
      dropdown,
      inputId,
      target,
      select,
      show,
    }
  },
}
</script>
