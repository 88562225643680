<template>
  <div
    class="tw-w-full tw-flex tw-flex-col tw-items-center tw-px-4 xs:tw-px-6 tw-mt-6 xs:tw-mt-16"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ViewContainer',
}
</script>
