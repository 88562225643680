import { PUBLIC_BASE_URL, PUBLIC_API_KEY } from '@config'
import { queryString } from '@helpers/utils.js'
import { normalizeSort } from '@composables'
import { createClient } from '@services'

const { get } = createClient()

/* API request to get mods for game */
export async function getModsForGameRequest(gameId, query) {
  query = { ...query, api_key: PUBLIC_API_KEY }
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/${gameId}/mods${queryString(query)}`
  )

  const sort = query?._sort ? normalizeSort(query._sort) : ''

  return { ...data, result_sort: sort }
}

/* API request to get mod */
export async function getModRequest(gameId, modId) {
  const query = { api_key: PUBLIC_API_KEY }
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/${gameId}/mods/${modId}${queryString(query)}`
  )
  return data
}

export async function getDependenciesRequest(gameId, modId) {
  const query = { api_key: PUBLIC_API_KEY }
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/${gameId}/mods/${modId}/dependencies${queryString(
      query
    )}`
  )
  return data
}
