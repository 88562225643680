const interfaceName = 'Anno7'
const DEBUG = true

function _debugLog(fnName) {
  if (DEBUG) {
    console.log('Calling', fnName)

    if (!global[interfaceName]) {
      console.warn('Missing global interface:', interfaceName)
    } else if (!global[interfaceName][fnName]) {
      console.warn('Missing implementation for:', fnName)
    }
  }

  function log() {
    if (DEBUG) console.log(`${fnName}:`, ...arguments)
  }

  function err() {
    if (DEBUG) console.error(`${fnName}:`, ...arguments)
  }

  return {
    err,
    log,
  }
}

/**
 * Returns true if the interface is defined on the global object
 */
export function isDefined() {
  return !!global[interfaceName]
}

/**
 * Get the logged in user's access token from the SDK
 * @returns {Promise<string>?} The user's access token
 */
export async function getAuthToken() {
  const { log, err } = _debugLog('getAuthToken')

  try {
    const result = await global[interfaceName]?.getAuthToken?.()
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Set the logged in user's access token on the SDK
 * @param {string} token The user's access token
 * @returns {Promise<void>?}
 */
export async function setAuthToken(token) {
  const { log, err } = _debugLog('setAuthToken')

  try {
    const result = await global[interfaceName]?.setAuthToken?.(token)
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Set the logged in user's ID on the SDK
 * @param {string} userId The user's ID
 * @returns {Promise<void>?}
 */
export async function setUserId(userId) {
  const { log, err } = _debugLog('setUserId')

  try {
    const result = await global[interfaceName]?.setUserId?.(userId.toString())
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Notify the SDK that a mod has been subscribed to or unsubscribed from
 * @param {number} modId The ID of the mod
 * @param {boolean} isSubscribed true if subscribed, false if unsubscribed
 * @returns {Promise<void>?}
 */
export async function notifySubscriptionChange(modId, isSubscribed) {
  const { log, err } = _debugLog('notifySubscriptionChange')

  try {
    const result = await global[interfaceName]?.notifySubscriptionChange?.(
      modId,
      isSubscribed
    )
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Get the list of activated mod IDs from the SDK
 * @returns {Promise<number[]>?} An array of mod IDs
 */
export async function getActivatedIds() {
  const { log, err } = _debugLog('getActivations')

  try {
    const result = await global[interfaceName]?.getActivations?.()
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Set the list of activated mod IDs on the SDK
 * @param {number[]} modIds An array of mod IDs
 * @returns {Promise<void>?}
 */
export async function setActivatedIds(modIds) {
  const { log, err } = _debugLog('setActivations')

  try {
    const result = await global[interfaceName]?.setActivations?.(modIds)
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Notify the SDK that a mod has been activated or deactivated
 * @param {number} modId The ID of the mod
 * @param {boolean} isActivated true if activated, false if deactivated
 * @returns {Promise<void>?}
 */
export async function notifyActivationChange(modId, isActivated) {
  const { log, err } = _debugLog('notifyActivationChange')

  try {
    const result = await global[interfaceName]?.notifyActivationChange?.(
      modId,
      isActivated
    )
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Get the list of locally installed mods from the SDK
 * @returns {Promise<LocalMod[]>?}
 */
export async function getLocalMods() {
  const { log, err } = _debugLog('getLocalMods')

  try {
    const result = await global[interfaceName]?.getLocalMods?.()
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Get the list of activated local mod IDs from the SDK
 * @returns {Promise<string[]>?} An array of mod IDs
 */
export async function getLocalActivatedIds() {
  const { log, err } = _debugLog('getLocalActivations')

  try {
    const result = await global[interfaceName]?.getLocalActivations?.()
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Set the list of activated mod IDs on the SDK
 * @param {string[]} modIds An array of mod IDs
 * @returns {Promise<void>?}
 */
export async function setLocalActivatedIds(modIds) {
  const { log, err } = _debugLog('setLocalActivations')

  try {
    const result = await global[interfaceName]?.setLocalActivations?.(modIds)
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Notify the SDK that a local mod has been activated or deactivated
 * @param {string | number} modId The ID of the mod
 * @param {boolean} isActivated true if activated, false if deactivated
 * @returns {Promise<void>?}
 */
export async function notifyLocalActivationChange(modId, isActivated) {
  const { log, err } = _debugLog('notifyLocalActivationChange')

  try {
    const result = await global[interfaceName]?.notifyLocalActivationChange?.(
      modId.toString(),
      isActivated
    )
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Get the language the SDK is using
 * @returns {Promise<string>?}
 */
export async function getLanguage() {
  const { log, err } = _debugLog('getLanguage')

  try {
    const result = await global[interfaceName]?.getLanguage?.()
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Get the platform the SDK is running on
 * @returns {Promise<string>?}
 */
export async function getPlatform() {
  const { log, err } = _debugLog('getPlatform')

  try {
    const result = await global[interfaceName]?.getPlatform?.()
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}

/**
 * Get the portal the SDK is authed with
 * @returns {Promise<string>?}
 */
export async function getPortal() {
  const { log, err } = _debugLog('getPortal')

  try {
    const result = await global[interfaceName]?.getPortal?.()
    log(result)
    return result
  } catch (error) {
    err(error)
  }
}
