import { watchEffect, ref, computed, watch } from 'vue'
import { Config } from '@plugins/configLoader.js'
import { isObjEmpty } from '@helpers/utils'
import { useRoute } from '@composables'
import { themeStore } from '@stores'
import Color from 'color'
import {
  DEFAULT_SUCCESS,
  DEFAULT_WARNING,
  DEFAULT_DANGER,
  DEFAULT_PRIMARY,
  THEME_DEFAULT,
  DEFAULT_LIGHT,
  ADMIN_PRIMARY,
  DEFAULT_DARK,
  THEME_ADMIN,
  LIGHT_TEXT,
  THEME_HOME,
  ADMIN_DARK,
  HOME_DARK,
  DARK_TEXT,
} from '@config/colorsTheme.js'

const primary = ref(DEFAULT_PRIMARY)
const secondary = ref(Config.SECONDARY_COLOR)
const dark = ref(DEFAULT_DARK)
const light = ref(DEFAULT_LIGHT)
const success = ref(DEFAULT_SUCCESS)
const warning = ref(DEFAULT_WARNING)
const danger = ref(DEFAULT_DANGER)
const text = ref(LIGHT_TEXT)

const darkTemp = ref(DEFAULT_DARK)
const lightTemp = ref(DEFAULT_LIGHT)

let tempGameTheme = {}

const BLACK_PANEL_REF = '#222222'
const WHITE_PANEL_REF = '#f2f2f2'
// threshold range
// 1 = black
// 21 = white
const BLACK_THRESHOLD = 1.15
const WHITE_THRESHOLD = 20

const { getTheme } = themeStore()
export default function () {
  const { getGameId, routeMeta } = useRoute()
  const gameNameId = getGameId()

  const routeTheme = computed(() => routeMeta.value && routeMeta.value.theme)
  const primaryColor = computed(() => primary.value)
  const darkColor = computed(() => dark.value)
  const lightColor = computed(() => light.value)
  const successColor = computed(() => success.value)
  const warningColor = computed(() => warning.value)
  const dangerColor = computed(() => danger.value)
  const textColor = computed(() => text.value)

  // --light var is always white and cannot be changed (requirment)
  // this is to ensure the theming bg is white but panel / input
  // colors are still themable
  const themeVars = computed(() => {
    return {
      '--dark': dark.value,
      '--dark-1': Color(darkTemp.value).lighten(0.35).hex(),
      '--dark-2': Color(darkTemp.value).lighten(0.6).hex(),
      '--dark-3': Color(darkTemp.value).darken(0.15).hex(),
      '--dark-text': _selectTextColor(dark.value),
      '--light': '#ffffff',
      '--light-1': lightTemp.value,
      '--light-2': Color(lightTemp.value).darken(0.05).hex(),
      '--light-3': Color(lightTemp.value).darken(0.08).hex(),
      '--light-text': _selectTextColor(light.value),
      '--primary': primary.value,
      '--primary-hover': Color(primary.value).saturate(0.5).lighten(0.2).hex(),
      '--primary-text': _selectTextColor(primary.value),
      '--secondary': secondary.value,
      '--secondary-hover': Color(secondary.value)
        .saturate(0.5)
        .lighten(0.2)
        .hex(),
      '--tertiary': '#ca7f5f',
      '--secondary-off': '#998466',
      '--secondary-off-hover': Color('#998466')
        .saturate(0.5)
        .lighten(0.2)
        .hex(),
      '--success': success.value,
      '--success-hover': Color(success.value).saturate(0.5).lighten(0.2).hex(),
      '--success-text': _selectTextColor(success.value),
      '--warning': warning.value,
      '--warning-hover': Color(warning.value).saturate(0.5).lighten(0.2).hex(),
      '--warning-text': _selectTextColor(warning.value),
      '--danger': danger.value,
      '--danger-hover': Color(danger.value).saturate(0.5).lighten(0.2).hex(),
      '--danger-text': _selectTextColor(danger.value),
    }
  })

  function watchTheme() {
    watchEffect(() => {
      const theme = getTheme(gameNameId.value)
      _setThemable(theme.value)
    })

    watch(
      themeVars,
      () => {
        _pushThemeVars()
      },
      { immediate: true }
    )
  }

  // theming options now set at route level
  // default use game theming
  function _setThemable(theme) {
    if (routeTheme.value === THEME_DEFAULT) {
      // reset theme if in none themable area
      updateTheme()
    } else if (routeTheme.value === THEME_HOME) {
      _setHomeTheme()
    } else if (routeTheme.value === THEME_ADMIN) {
      // apply custom admin theme
      _setAdminTheme()
    } else {
      // use game colors
      if (theme && !isObjEmpty(theme)) {
        updateTheme(theme)
      }
    }
  }

  // functions below used to theme modals using default theme
  // this can be seen then opening the login modal from
  // a themed game, the game theme does not carry into modal
  function setDefaultThemeVars() {
    tempGameTheme = getTheme(gameNameId.value).value
    updateTheme()
  }

  function resetGameThemeVars() {
    if (tempGameTheme && !isObjEmpty(tempGameTheme)) {
      updateTheme(tempGameTheme)
      tempGameTheme = {}
    }
  }

  function updateTheme({
    danger = DEFAULT_DANGER,
    dark = DEFAULT_DARK,
    light = DEFAULT_LIGHT,
    primary = DEFAULT_PRIMARY,
    success = DEFAULT_SUCCESS,
    warning = DEFAULT_WARNING,
  } = {}) {
    updatePrimaryColor(primary)
    updateSecondaryColor(Config.SECONDARY_COLOR)
    updateDarkColor(dark)
    updateLightColor(light)
    updateSuccessColor(success)
    updateWarningColor(warning)
    updateDangerColor(danger)
  }

  function _setHomeTheme({ dark = HOME_DARK } = {}) {
    updateTheme({ dark })
  }

  function _setAdminTheme({
    danger = DEFAULT_DANGER,
    dark = ADMIN_DARK,
    light = DEFAULT_LIGHT,
    primary = ADMIN_PRIMARY,
    success = DEFAULT_SUCCESS,
    warning = DEFAULT_WARNING,
  } = {}) {
    updatePrimaryColor(primary)
    updateDarkColor(dark)
    updateLightColor(light)
    updateSuccessColor(success)
    updateWarningColor(warning)
    updateDangerColor(danger)
  }

  function updatePrimaryColor(color) {
    primary.value = color
  }

  function updateSecondaryColor(color) {
    secondary.value = color
  }

  function updateDarkColor(color) {
    _checkDarkContrast(color)
    dark.value = color
  }

  function updateLightColor(color) {
    _checkLightContrast(color)
    light.value = color
  }

  function updateSuccessColor(color) {
    success.value = color
  }

  function updateWarningColor(color) {
    warning.value = color
  }

  function updateDangerColor(color) {
    danger.value = color
  }

  function _selectTextColor(bgColor) {
    return Color(bgColor).isDark() ? DARK_TEXT : LIGHT_TEXT
  }

  // the functions below check the incoming color contrast
  // if white or dark a different panel color is applied
  // to avoid full white or full black themes
  function _checkDarkContrast(color) {
    return Color().contrast(Color(color)) < BLACK_THRESHOLD
      ? (darkTemp.value = BLACK_PANEL_REF)
      : (darkTemp.value = color)
  }

  function _checkLightContrast(color) {
    return Color().contrast(Color(color)) > WHITE_THRESHOLD
      ? (lightTemp.value = WHITE_PANEL_REF)
      : (lightTemp.value = color)
  }

  function _pushThemeVars() {
    for (let [key, value] of Object.entries(themeVars.value)) {
      document.documentElement.style.setProperty(key, value)
    }
  }

  return {
    setDefaultThemeVars,
    resetGameThemeVars,
    updateSuccessColor,
    updateWarningColor,
    updatePrimaryColor,
    updateDangerColor,
    updateLightColor,
    updateDarkColor,
    successColor,
    warningColor,
    primaryColor,
    updateTheme,
    dangerColor,
    lightColor,
    watchTheme,
    darkColor,
    themeVars,
    textColor,
  }
}
